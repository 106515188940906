import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.27164 11.7927L5.86035 8.20398C6.06399 8.00052 6.33446 7.88835 6.62243 7.88835C6.91002 7.88835 7.18049 8.00052 7.38414 8.20398L8.17935 8.9992L7.44907 9.72947C7.19686 9.98168 7.19686 10.3903 7.44907 10.6423C7.57518 10.7684 7.74024 10.8315 7.9055 10.8315C8.07056 10.8315 8.236 10.7684 8.36192 10.6423L9.0922 9.91185L9.8876 10.7071C10.0909 10.9105 10.203 11.1812 10.203 11.4691C10.203 11.7569 10.0909 12.0276 9.8876 12.231L6.2987 15.8198C5.89159 16.2263 5.1822 16.2267 4.77509 15.8198L2.27164 13.3161C1.85173 12.8964 1.85173 12.2126 2.27164 11.7927ZM7.98047 6.5292C7.98047 6.24142 8.09265 5.97076 8.29611 5.7673L11.8848 2.17859C12.0885 1.97513 12.3589 1.86296 12.6469 1.86296C12.9347 1.86296 13.2051 1.97513 13.4088 2.17859L15.9123 4.68224C16.332 5.10196 16.332 5.78575 15.9123 6.20566L12.3232 9.79436C11.9159 10.2009 11.2065 10.2013 10.7996 9.79436L10.0042 8.99915L10.7346 8.26888C10.9866 8.01667 10.9866 7.60805 10.7346 7.35603C10.4824 7.10382 10.0734 7.10382 9.82178 7.35603L9.09132 8.08649L8.29611 7.29128C8.09265 7.08782 7.98047 6.81717 7.98047 6.5292ZM3.86201 16.7332C4.3094 17.1808 4.90397 17.4272 5.53657 17.4272C6.16935 17.4272 6.76392 17.1808 7.21131 16.7332L10.8002 13.1445C11.2476 12.6973 11.494 12.1022 11.494 11.4698C11.494 11.4442 11.4874 11.4197 11.4866 11.3943C11.512 11.395 11.5363 11.4016 11.5619 11.4016C12.1945 11.4016 12.7893 11.1553 13.2367 10.7077L16.8257 7.11897C17.7489 6.19558 17.7489 4.69306 16.8257 3.76986L14.3223 1.26622C13.8749 0.81864 13.2801 0.572266 12.6475 0.572266C12.0148 0.572266 11.42 0.81864 10.9726 1.26622L7.3839 4.85492C6.93651 5.30212 6.68995 5.89726 6.68995 6.52967C6.68995 6.55526 6.69654 6.57973 6.69729 6.60514C6.67188 6.60439 6.6476 6.5978 6.62219 6.5978C5.98941 6.5978 5.39465 6.84418 4.94726 7.29175L1.35856 10.8805C0.435169 11.8038 0.435169 13.3064 1.35856 14.2296L3.86201 16.7332Z"
        fill="#737373"
      />
    </Svg>
  );
};

export default Icon;
