import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Community"),
    href: "https://www.poolschain.org/#community",
    items: [
      {
        label: t("Facebook"),
        href: "https://www.facebook.com/bpoolsclub",
        isHighlighted: false,
      },
      {
        label: t("Telegram"),
        href: "https://t.me/Poolsclub",
      },
      {
        label: t("Discord"),
        href: "https://discord.com/invite/SVy65eh4aJ",
      },
      {
        label: t("Twitter"),
        href: "https://twitter.com/pools_club",
      },
    ],
  },
  {
    label: t("Ecosystem"),
    href: "https://www.poolschain.org/",
    items: [
      {
        label: t("POOLS Scan"),
        href: "https://scan.poolsmobility.com/",
        isHighlighted: false,
      },
      {
        label: t("POOLS Phone"),
        href: "https://poolsmobility.com/",
      },
      {
        label: t("POOLS Mall"),
        href: "https://mall.poolsmobility.com/",
      },
      {
        label: t("POOLS Wallet"),
        href: "https://wallet.poolsmobility.com/",
      },
      {
        label: t("NFT Marketplace"),
        href: "https://nftmarket.poolsmobility.com/",
      },
    ],
  },
  // {
  //   label: t("Help"),
  //   items: [
  //     {
  //       label: t("Customer Support"),
  //       href: "https://docs.labs.poolschain.org/contact-us/customer-support",
  //     },
  //     {
  //       label: t("Troubleshooting"),
  //       href: "https://docs.labs.poolschain.org/help/troubleshooting",
  //     },
  //     {
  //       label: t("Guides"),
  //       href: "https://docs.labs.poolschain.org/get-started",
  //     },
  //   ],
  // },
  {
    label: t("Gitbook Docs"),
    href: "https://pools-chain.gitbook.io/documentation/",
    items: [
      // {
      //   label: "Gitbook Docs",
      //   href: "https://github.com/pancakeswap",
      // },
      // {
      //   label: t("Documentation"),
      //   href: "https://docs.labs.poolschain.org",
      // },
      // {
      //   label: t("Bug Bounty"),
      //   href: "https://docs.labs.poolschain.org/code/bug-bounty",
      // },
      // {
      //   label: t("Audits"),
      //   href: "https://docs.labs.poolschain.org/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited",
      // },
      // {
      //   label: t("Careers"),
      //   href: "https://docs.labs.poolschain.org/hiring/become-a-chef",
      // },
    ],
  },
];
