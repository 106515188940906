import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: 'Pools Chain | %s ',
  defaultTitle: 'Pools Chain',
  description: 'Tools and Resources for building on Pools. Simplify and accelerate your contract development process',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@PoolsChain',
    site: '@PoolsChain',
  },
  openGraph: {
    title: '🥞 Pools Chain - Tools and Resources for building on Pools',
    description: 'Tools and Resources for building on Pools. Simplify and accelerate your contract development process',
    images: [{ url: `${process.env.NEXT_PUBLIC_BASE_URL}/images/hero.png` }],
  },
}
