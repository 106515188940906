import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 21 20" {...props}>
      <rect x="0.5" width="20" height="20" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.73992 9.35479C6.74653 9.35479 5.12467 7.73331 5.12467 5.73992C5.12467 3.74653 6.74653 2.12467 8.73992 2.12467C10.7333 2.12467 12.355 3.74653 12.355 5.73992C12.355 7.73331 10.7333 9.35479 8.73992 9.35479ZM8.74039 0.833984C6.03498 0.833984 3.83398 3.03498 3.83398 5.7402C3.83398 8.44561 6.03498 10.6462 8.74039 10.6462C11.4458 10.6462 13.6464 8.44561 13.6464 5.7402C13.6464 3.03498 11.4458 0.833984 8.74039 0.833984Z"
        fill="#A1A1A1"
        fill-opacity="0.631373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4535 11.1617C14.8128 11.1617 14.2917 10.6405 14.2917 9.99961C14.2917 9.35873 14.8128 8.83756 15.4535 8.83756C16.0942 8.83756 16.6156 9.35873 16.6156 9.99961C16.6156 10.6405 16.0942 11.1617 15.4535 11.1617ZM7.25553 17.7095V15.9616H10.225V16.2797C10.2158 16.3418 10.215 16.4054 10.225 16.4698V17.7095H7.25553ZM15.4543 7.54688C14.1016 7.54688 13.0013 8.64718 13.0013 10.0001C13.0013 10.9705 13.5689 11.8085 14.3881 12.206C13.8498 13.5875 12.8285 14.7145 11.5165 15.3893V15.3162C11.5165 14.9597 11.2275 14.6706 10.8709 14.6706H6.61042C6.25375 14.6706 5.96484 14.9597 5.96484 15.3162V18.3552C5.96484 18.7116 6.25375 19.0007 6.61042 19.0007H10.8709C11.2275 19.0007 11.5165 18.7116 11.5165 18.3552V16.8171C13.4709 16.023 14.9868 14.4318 15.6847 12.4416C16.9295 12.3249 17.9075 11.2752 17.9075 10.0001C17.9075 8.64718 16.807 7.54688 15.4543 7.54688Z"
        fill="#A1A1A1"
        fill-opacity="0.631373"
      />
    </Svg>
  );
};

export default Icon;
