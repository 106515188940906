import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.12467 2.12467H17.8759V4.16888H16.8428H3.15723H2.12467V2.12467ZM16.1973 12.3379H13.4676C13.331 12.2905 13.1808 12.288 13.0402 12.3379H6.73871C6.7197 12.3372 6.70069 12.3368 6.68187 12.3379H3.80273V5.46126H16.1973V12.3379ZM1.47956 5.46051H2.5123V12.9826C2.5123 13.339 2.8014 13.6281 3.15769 13.6281H5.53467L2.61469 18.173C2.42177 18.4728 2.50892 18.8724 2.80893 19.0649C2.91697 19.1342 3.03761 19.1675 3.15732 19.1675C3.36963 19.1675 3.5776 19.0629 3.7007 18.8707L7.06882 13.6281H12.9285L16.2999 18.8816C16.4235 19.0738 16.6315 19.1784 16.8438 19.1784C16.9631 19.1784 17.0841 19.1451 17.1922 19.0762C17.4922 18.8837 17.5792 18.4841 17.3868 18.1841L14.4631 13.6281H16.8432C17.1997 13.6281 17.4888 13.339 17.4888 12.9826V5.46051H18.5219C18.8782 5.46051 19.1675 5.17141 19.1675 4.81493V1.47956C19.1675 1.12327 18.8782 0.833984 18.5219 0.833984H1.47956C1.12327 0.833984 0.833984 1.12327 0.833984 1.47956V4.81493C0.833984 5.17141 1.12327 5.46051 1.47956 5.46051Z"
        fill="#A1A1A1"
        fill-opacity="0.631373"
      />
    </Svg>
  );
};

export default Icon;
