import noop from "lodash/noop";
import { DropdownMenuItems, DropdownMenuItemType } from "./types";

export const ItemsMock: DropdownMenuItems[] = [
  {
    label: "POOLS Scan",
    href: "https://scan.poolsmobility.com/",
  },
  {
    label: "POOLS Phone",
    href: "https://poolsmobility.com/",
  },
  {
    label: "POOLS Mall",
    href: "https://mall.poolsmobility.com/",
  },
  {
    label: "POOLS Wallet",
    href: "https://www.poolschain.org/",
  },
  {
    label: "NFT Marketplace",
    href: "https://v2litepaper.labs.poolschain.org/",
  },
];


export const CommunityItems: DropdownMenuItems[] = [
  {
    label: "Facebook",
    href: "https://www.facebook.com/bpoolsclub",
  },
  {
    label: "Telegram",
    href: "https://t.me/Poolsclub",
  },
  {
    label: "Discord",
    href: "https://discord.com/invite/sD4CbW4Ys8",
  },
  {
    label: "Twitter",
    href: "https://twitter.com/pools_club",
  }
];

