import { useIsMounted } from "@pancakeswap/hooks";
import { useTranslation } from "@pancakeswap/localization";
import React, { useEffect } from "react";
import { Box, Flex } from "../Box";
import { Link } from "../Link";
import {
  StyledButtonEmail,
  StyledFooter,
  StyledFooterLink,
  StyledForm,
  StyledInputEmail,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToastValid,
  StyledToastValidOk,
} from "./styles";

import { Text } from "@pancakeswap/uikit";
import styled from "styled-components";
import { useMatchBreakpoints } from "../..";
import { vars } from "../../css/vars.css";
import DropdownMenuPools from "../DropdownMenu/DropdownMenuPools";
import { ItemsMock, CommunityItems } from "../DropdownMenu/mock";
import PoolsLogo from "../Svg/PoolsLogo";
import { FooterProps } from "./types";
import Community from "../Svg/Icons/Community";

const DropDownIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : "13"}
      height={props.height ? props.height : "10"}
      viewBox="0 0 13 10"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.91677 10L0.851562 0L12.982 1.00947e-06L6.91677 10Z" fill="white" />
    </svg>
  );
};

const StyledLink = styled("a")`
  display: flex;
  .logo {
    width: 61px;
    height: 47px;
  }
`;

export const PageHr = styled.hr`
  border: none;
  height: 1px;
  color: #4e4e4e; /* old IE */
  background-color: #4e4e4e; /* Modern Browsers */
`;

const styleTextFooter = {
  fontSize: "14px",
  lineHeight: "16px",
};

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const { isMobile, isDesktop } = useMatchBreakpoints();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!isDark) toggleTheme(true);
  }, []);
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [isNotValid, setIsValid] = React.useState(false);
  const [okStatus, setOkStatus] = React.useState(false);

  const validateEmail = (emailValid: any) => {
    return String(emailValid)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const showToastNotValid = () => {
    setIsValid(true);
    setTimeout(function () {
      setIsValid(false);
    }, 3000);
  };

  const showToastOkStatus = () => {
    setOkStatus(true);
    setTimeout(function () {
      setOkStatus(false);
    }, 3000);
  };
  const sendEmail = (event: any) => {
    if (!validateEmail(email)) {
      showToastNotValid();
      return;
    }
    event.preventDefault();
    const myHeaders: Headers = new Headers();
    myHeaders.append("authority", "api.emailjs.com");
    const formdata: FormData = new FormData();
    formdata.append("user_email", email);
    formdata.append("lib_version", "3.2.0");
    formdata.append("service_id", "service_frno125");
    formdata.append("template_id", "template_f5zzpih");
    formdata.append("user_id", "SKDSvkpfXo-o3P0J9");

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.emailjs.com/api/v1.0/email/send-form", requestOptions)
      .then((response: Response) => response.text())
      .then((result: string) => {
        showToastOkStatus();
        console.log(result);
      })
      .catch((error: Error) => console.log("error", error));
    setEmail("");
  };

  return (
    <>
      <PageHr />

      {isDesktop ? (
        <StyledFooter
          p={["40px 16px", null, "56px 40px 32px 40px"]}
          position="relative"
          {...props}
          justifyContent="center"
        >
          <Flex flexDirection="column" width={["100%", null, "1390px"]}>
            {/* <StyledIconMobileContainer display={["block", null, "none"]}>
          <PoolsLogo width="130px" />
        </StyledIconMobileContainer> */}
            <Flex
              order={[2, null, 1]}
              flexDirection={["column", null, "row"]}
              justifyContent="space-between"
              alignItems="flex-start"
              mb={["42px", null, "36px"]}
            >
              <Box mt={["10px"]}>
                <StyledLink as="a">
                  <PoolsLogo className="logo" />
                </StyledLink>
                <Text mt={["30px"]} fontSize="14px">
                  {t("PLAYGROUND PLANET PTE. LTD.")}
                </Text>
                <Text fontSize="14px">{t('Copyright ©2023 Pools Phone All Right Reserved')}</Text>
              </Box>
              {/* <Box>
                <Link
                  data-theme="dark"
                  href={"https://www.poolschain.org/#community"}
                  target="_blank"
                  rel="noreferrer noopener"
                  bold={false}
                  color={"text"}
                >
                  {" "}
                  Community
                </Link>

                <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} />
              </Box> */}
              <Box width="40%">
                <StyledFooterLink>
                  {items?.map((item) => (
                    <Box pr="20px" pl="20px">
                      <StyledList key={item.label}>
                        <StyledListItem>
                          {item.href ? (
                            <Link
                              data-theme="dark"
                              href={item.href}
                              target="_blank"
                              rel="noreferrer noopener"
                              color={false ? vars.colors.warning : "text"}
                              bold={false}
                            >
                              {item.label}
                            </Link>
                          ) : (
                            <StyledText>{item.label}</StyledText>
                          )}
                        </StyledListItem>
                        {item.items?.map(({ label, href, isHighlighted = false }) => (
                          <StyledListItem key={label}>
                            {href ? (
                              <Link
                                data-theme="dark"
                                href={href}
                                target="_blank"
                                rel="noreferrer noopener"
                                color={isHighlighted ? vars.colors.warning : "text"}
                                bold={false}
                              >
                                {label}
                              </Link>
                            ) : (
                              <StyledText>{label}</StyledText>
                            )}
                          </StyledListItem>
                        ))}
                      </StyledList>
                    </Box>
                  ))}
                </StyledFooterLink>
              </Box>
              <Box >
                <Text textAlign="right" fontSize="20px" fontWeight="500" lineHeight="30px">
                  {t("Get the latest updates on products")}
                </Text>
                <Text textAlign="right" fontSize="20px" fontWeight="500" lineHeight="30px">
                  {t("and developments.")}
                </Text>
                <Text textAlign="right" fontSize="16px">
                  {t("Hear it first from POOLS")}
                </Text>
                <Flex justifyContent="flex-end" mt="20px">
                  <StyledInputEmail
                    placeholder={t("Enter your mail")}
                    type="email"
                    name="user_email"
                    id="email"
                    value={email}
                    required
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <StyledButtonEmail
                    type="button"
                    value="Send"
                    variant="primary"
                    onClick={(event: any) => sendEmail(event)}
                  >
                    {t("Send email")}
                  </StyledButtonEmail>
                </Flex>
                {isNotValid && (
                  <Box mt="10px">
                    <StyledToastValid>
                      <Box ml="10px" display="flex">
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 inline w-5 h-5 mr-3"
                          fill="currentColor"
                          width="15px"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <Box ml="10px">{t("Please enter a valid email address.")}</Box>
                      </Box>
                    </StyledToastValid>
                  </Box>
                )}
                {okStatus && (
                  <Box mt="10px">
                    <StyledToastValidOk>
                      <Box ml="10px" display="flex">
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 inline w-5 h-5 mr-3"
                          fill="currentColor"
                          width="15px"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <Box ml="10px">{t("Email Sent Successfully !")}</Box>
                      </Box>
                    </StyledToastValidOk>
                  </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        </StyledFooter>
      ) : (
        <StyledFooter
          p={["40px 16px", null, "56px 40px 32px 40px"]}
          position="relative"
          {...props}
          justifyContent="center"
        >
          <Flex flexDirection="column" width={["100%", null, "1500px"]}>
            {/* <StyledIconMobileContainer display={["block", null, "none"]}>
          <PoolsLogo width="130px" />
        </StyledIconMobileContainer> */}
            <Flex
              order={[1, 1, 1]}
              flexDirection={["column", null, null]}
              justifyContent="center"
              alignItems="center"
              mb={["42px", null, "36px"]}
            >
              <Box>
                <StyledLink as="a">
                  <PoolsLogo className="logo" />
                </StyledLink>
              </Box>
              {/* <Box mt={["20px"]}>
                <Link
                  data-theme="dark"
                  href={"https://pools-chain.gitbook.io/documentation/"}
                  target="_blank"
                  rel="noreferrer noopener"
                  color={false ? vars.colors.warning : "text"}
                  bold={false}
                >
                  About Pools
                </Link>
              </Box> */}

              <Box mt={["30px"]}>
                <DropdownMenuPools
                  isBottomNav={true}
                  showItemsOnMobile={true}
                  items={CommunityItems}
                  activeItem=""
                  setTabLink={undefined}
                >
                  <Box display="flex">
                    <Box style={styleTextFooter}>{t("Community")}</Box>
                    <Box mt={["-2px"]} ml={["2px"]}>
                      <DropDownIcon width="9" height="6" />
                    </Box>
                  </Box>
                </DropdownMenuPools>
              </Box>

              <Box mt={["30px"]}>
                <DropdownMenuPools
                  showItemsOnMobile={true}
                  isBottomNav={true}
                  items={ItemsMock}
                  activeItem=""
                  setTabLink={undefined}
                >
                  <Box display="flex">
                    <Box style={styleTextFooter}>{t("Ecosystem")}</Box>
                    <Box mt={["-2px"]} ml={["2px"]}>
                      <DropDownIcon width="9" height="6" />
                    </Box>
                  </Box>
                </DropdownMenuPools>
              </Box>

              <Box mt={["30px"]}>
                <Link
                  data-theme="dark"
                  href={"https://pools-chain.gitbook.io/documentation/getting-started/background"}
                  target="_blank"
                  rel="noreferrer noopener"
                  color={false ? vars.colors.warning : "text"}
                  bold={false}
                >
                  <Box style={styleTextFooter}>Gitbook Docs</Box>
                </Link>
              </Box>

              <Box mt="20px" style={{ justifyContent: "center" }}>
                <Text style={{ textAlign: "center", fontSize: "14px", lineHeight: "21px" }}>
                  {t('Get the latest updates on products and developments.')}
                </Text>

                <Text mt="10px" style={{ textAlign: "center", fontSize: "16px", lineHeight: "20px" }}>
                  {t('Hear it first from POOLS')}
                </Text>
                <Box display="flex" mt="20px">
                  <StyledInputEmail
                    placeholder={t("Enter your mail")}
                    type="email"
                    name="user_email"
                    id="email"
                    value={email}
                    required
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <StyledButtonEmail type="button" value="Send" onClick={(event: any) => sendEmail(event)}>
                    {t("Send email")}
                  </StyledButtonEmail>
                </Box>
              </Box>

              {isNotValid && (
                <Box mt="10px">
                  <StyledToastValid>
                    <Box ml="10px" display="flex">
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 inline w-5 h-5 mr-3"
                        fill="currentColor"
                        width="15px"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <Box ml="10px">{t("Please enter a valid email address.")}</Box>
                    </Box>
                  </StyledToastValid>
                </Box>
              )}
              {okStatus && (
                <Box mt="10px">
                  <StyledToastValidOk>
                    <Box ml="10px" display="flex">
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 inline w-5 h-5 mr-3"
                        fill="currentColor"
                        width="15px"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <Box ml="10px">{t("Email Sent Successfully !")}</Box>
                    </Box>
                  </StyledToastValidOk>
                </Box>
              )}

              <Box mt="20px">
                <Text fontSize="14px">{t("PLAYGROUND PLANET PTE. LTD.")}</Text>
                <Text fontSize="10px">{t("Copyright © 2023 Pools Phone All Right Reserved")}</Text>
              </Box>
              {/* 
              <Box mt={["20px"]}>
                <StyledSocialLinks />
              </Box> */}
              {/* <Box mt={["20px"]}>
                <Text textAlign="center">Get the latest updates on products and developments.</Text>
              </Box> */}
            </Flex>
          </Flex>
        </StyledFooter>
      )}
    </>
  );
};

export default MenuItem;
