import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 21 20" {...props}>
      <rect x="0.5" width="20" height="20" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7087 6.06548C17.3261 6.44792 16.8394 6.67882 16.3013 6.73159C16.0552 6.75628 15.8452 6.92046 15.7619 7.15324C15.6787 7.38621 15.737 7.64613 15.9121 7.82124L18.1012 10.0105L16.7075 11.4042C16.5301 11.0042 16.2802 10.6372 15.9664 10.3238C15.2874 9.64466 14.3846 9.27108 13.4246 9.27108C12.4643 9.27108 11.5616 9.64466 10.8827 10.3238C10.2036 11.0025 9.82961 11.9054 9.82961 12.8657C9.82961 13.8257 10.2036 14.7283 10.8827 15.4075C11.1965 15.7213 11.5633 15.9712 11.9633 16.1488L10.4901 17.622L2.87858 10.0105L10.4901 2.39922L12.6793 4.58849C12.8545 4.7636 13.1149 4.82316 13.3473 4.73853C13.5801 4.65522 13.7443 4.44524 13.7686 4.19889C13.822 3.66076 14.0525 3.17409 14.4349 2.79165C14.8722 2.35454 15.4535 2.11328 16.0717 2.11328C16.6902 2.11328 17.2715 2.35417 17.7087 2.79165C18.6114 3.6945 18.6114 5.16263 17.7087 6.06548ZM18.6138 1.88725C17.9349 1.20813 17.0322 0.833984 16.0719 0.833984C15.1119 0.833984 14.2091 1.20813 13.5301 1.88725C13.2314 2.186 12.9896 2.53376 12.8133 2.913L10.9426 1.04226C10.6927 0.792329 10.2878 0.792329 10.0377 1.04226L1.52143 9.55868C1.2715 9.80861 1.2715 10.2135 1.52143 10.4636L10.0377 18.9798C10.1577 19.0999 10.3204 19.1674 10.4902 19.1674C10.66 19.1674 10.8227 19.0999 10.9426 18.9798L13.6594 16.263C13.8366 16.086 13.8941 15.8221 13.8068 15.5873C13.7194 15.3526 13.5034 15.1907 13.2538 15.1722C12.6995 15.1319 12.1789 14.894 11.7878 14.5031C11.3505 14.0658 11.1098 13.4845 11.1098 12.8663C11.1098 12.2474 11.3505 11.6663 11.7878 11.2292C12.2251 10.7918 12.8063 10.5514 13.4248 10.5514C14.043 10.5514 14.6243 10.7923 15.0616 11.2292C15.4525 11.6202 15.6902 12.1404 15.7307 12.6949C15.749 12.945 15.9113 13.1609 16.146 13.2483C16.3812 13.3361 16.6449 13.2779 16.8215 13.1007L19.459 10.4636C19.5789 10.3435 19.6464 10.1807 19.6464 10.011C19.6464 9.84141 19.5789 9.67856 19.459 9.55868L17.5883 7.68794C17.9669 7.51171 18.3151 7.26969 18.6138 6.97094C20.0156 5.56935 20.0156 3.28884 18.6138 1.88725Z"
        fill="url(#paint0_linear_2009_2005)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2009_2005"
          x1="10.7295"
          y1="0.833984"
          x2="10.7295"
          y2="19.1674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF3BF" />
          <stop offset="0.458333" stop-color="#E8CC93" />
          <stop offset="1" stop-color="#BCA578" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
