import { styled } from "styled-components";
import { Text, TextProps } from "../Text";

export const StyledBottomNavItem = styled.button`
  display: block;
  border: 0;
  background: transparent;
  cursor: pointer;
  height: 44px;
  padding: 4px 12px;
  &:hover {
    border-radius: 16px;
  }
  &:hover,
  &:hover div {
    background: ${({ theme }) => theme.colors.tertiary};
  }
`;

export const StyledBottomNavText = styled(Text) <TextProps & { $isActive: boolean }>`
  display: -webkit-box;
  overflow: hidden;
  user-select: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  background: ${({ $isActive }) => ($isActive ? 'linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)' : '')};
  -webkit-background-clip: ${({ $isActive }) => ($isActive ? 'text' : '')};
  -webkit-text-fill-color: ${({ $isActive }) => ($isActive ? 'transparent' : '')};
`;
