import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="35" height="33" viewBox="0 0 35 33" fill="url(#paint0_linear_1728_14217)" {...props}>
      <rect  x="0.078125" y="0.498047" width="34.2069" height="32" rx="15" fill="url(#paint0_linear_1728_14214)" />
      <path
        d="M26.2802 9.86785C26.0281 12.6786 24.9359 19.5098 24.3814 22.6586C24.1461 23.9928 23.6756 24.4376 23.2387 24.491C22.2641 24.5799 21.5248 23.8149 20.5838 23.1567C19.1051 22.1249 18.2649 21.4845 16.8366 20.4883C15.173 19.332 16.2485 18.6915 17.2063 17.6597C17.4583 17.3929 21.76 13.2479 21.844 12.8743C21.8557 12.8177 21.8542 12.759 21.8395 12.7032C21.8249 12.6474 21.7976 12.5962 21.76 12.5541C21.6592 12.4651 21.5248 12.5007 21.4072 12.5185C21.2559 12.5541 18.9034 14.2085 14.3161 17.4818C13.6439 17.9621 13.039 18.2112 12.5013 18.1934C11.8964 18.1756 10.7537 17.8376 9.89674 17.5352C8.83812 17.1794 8.01475 16.9837 8.08197 16.3611C8.11557 16.0409 8.53566 15.7206 9.32543 15.3826C14.2321 13.1234 17.4919 11.629 19.1219 10.9174C23.7933 8.85384 24.7511 8.49805 25.3896 8.49805C25.524 8.49805 25.8433 8.53363 26.0449 8.71152C26.213 8.85384 26.2634 9.04953 26.2802 9.19184C26.2634 9.29858 26.297 9.6188 26.2802 9.86785Z"
        fill="#BDA76B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1728_14214"
          x1="17.1816"
          y1="0.498047"
          x2="17.1816"
          y2="32.498"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="#373737" />
        </linearGradient>
      </defs>{" "}
    </Svg>
  );
};

export default Icon;
