import { EN } from './config/languages'
import * as lVI from './config/locales/vi-VN.json'
import * as lEN from './config/locales/en-US.json'
import * as lKO from './config/locales/ko-KR.json'

export const LS_KEY = 'pancakeswap_language'

export const fetchLocale = async (locale: string) => {
  if (locale === 'vi-VN') {
    return lVI
  }
  if (locale === 'en-US') {
    return lEN
  }
  if (locale === 'ko-KR') {
    return lKO
  }
  return null
}

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY)

    return codeFromStorage || EN.locale
  } catch {
    return EN.locale
  }
}
