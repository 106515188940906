import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface TabLink {
  tabLink: string
}

const initialState: TabLink = {
  tabLink: '/',
}

export const rtabLink = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setTabLink: (state, action: PayloadAction<string>) => {
      state.tabLink = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTabLink } = rtabLink.actions

export const tabLink = rtabLink.reducer
