import React, { useState } from "react";
import Text from "../Text/Text";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import LanguageIcon from "../Svg/Icons/Language";
import MenuButton from "./MenuButton";
import { Colors } from "../../theme";
import { Language } from "./types";
import { Position } from "../Dropdown/types";
import { Scale } from "../Button/types";
import { Box, Flex } from "../Box";

const DropDownIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : "13"}
      height={props.height ? props.height : "10"}
      viewBox="0 0 13 10"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.91677 10L0.851562 0L12.982 1.00947e-06L6.91677 10Z" fill="white" />
    </svg>
  );
};

interface Props {
  currentLang: string;
  langs: Language[];
  setLang: (lang: Language) => void;
  color: keyof Colors;
  dropdownPosition?: Position;
  buttonScale?: Scale;
  hideLanguage?: boolean;
}

const LangSelector: React.FC<React.PropsWithChildren<Props>> = ({
  currentLang,
  langs,
  color,
  setLang,
  dropdownPosition = "bottom",
  buttonScale = "md",
  hideLanguage = false,
}) => {
  const getIconLang = (lang: any) => {
    switch (lang) {
      case "en":
        return (
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABMpJREFUWIXtlmtQ1FUYxn/7ZwF3cZcVMC6yEAMIRFimhE6F2iSpoy4qRqYh4f0SoQniBWPSCWY0tRy0lNBRSh1LAUlqKkfIG4poKoJCiNxBLsttubiXPpSoAeKQM/aB37fznnnO87xz3plzoJ9+njGi9BVf1nhGvpshNjXR5ZZppJ8du+Om1mhNexJMHG5ZtnS8XUnd5CWjHq5bpO46v/OXcmXa5dohvZkOtZU0bFDZF4i//tZelIebQe/sSP3CIDwXTaalXceOn8rYfqKUtnv6LuKPJyuJmu5IpcLnkbqNOpONR+/weWpJj8aWMmPCpygJNiqhOWoH2uv5CA0zp2JUXIbl6o0UT1xGVWYukSoHsmNHEjzGBkEk6q2hXpGYCIRNsic7SEHgzljU00LR3byNdMFMhO/8ZqNO2ovmrTGYnMtC6r+AwoAIKKti+1wXTka9xGtu5n0yFkQi/L2tuBDqyMrTB2n1+4D2k5mYjH0Vy4wDyDevQvgirZQJh+pICQmlPjEO7TAPpL+mYzR+NrmhW3GW6vlxtRdJq17EY4j0ic3HvqAgfbUHce0XMPGbg2ZfEsZerlik7sIiaQeNSiWRBwsRMT3VcF/kbC1hvb8DrpczsYqLh5IKsFBQEzwLjzVz0AtGFN1tw9VG0uMMHL9UyzqVPW8XZtEUHYeusgbB7jkGRsxDGjSVDj0knKokJqmYBo320QD38XaW8alKiexoClbxidDYxMODCnQb4HZ1G7Y5f3QOmMhMgtmHcxgY9j4GU1OSs2qI/r6IorttnTpR0tmiLgEARCLwcZHTWlFH1bmcB0a+w1DYDuo2gF7dhPZKbmdN7DUUwVLxd2B1B3nlmq4+arW62wC90V2AviDKw61PAZ4WwrM07w8A/4MhFJ+6oe5SdLeTYqMwAUBfq0Z77dYDwcseCApZt4epK+qpzLjaubYe7YnE1oLMgkYMPbQp9t9yvXMxxMKU8ClKfD3MMXTcozXhKE0xezA0NCF2c0IaOZ+yDmOceuhGYTuISkC+bTdCXgEdZhIqZgUgDQkk+nglZ242dNEIAGamRkSqHLgUM4JgX2s6kk9S4/0OjZFbEYmNkMeuJOebOPyuWJJ4uqoHe8ivbMU5wBebM/upiY7AYCbFIv4AyhkhxJJD8goP3O0efU+ExePtuLZ5JJEqB4QrN6ibsBB18Fr01bWYhQWhTkskpMML1dZcrpe09GgOcOhsNaOjsknJrsfzoxnIL/5A7bJ5iJqaGRyzDZfFYSQMb2X7XBcGy42Bf4ZQW1BM86avaEv6DQSBAVPH0Ra+hNisdg78XoVO/+ACn/RD4u0sY1OgEz4ucqpvldMQswdZys+g09E+agQtEUs5XDsQcWP4Flr3HcNwT4vpmz4Yb1hOfLmULXElNLfpHtvx47j4ZxMTYq6iGmlFdMDzuO79hMKMGYhjdmF6LosBgYt4z38SYs2eI4jdnDCLnE+y9TCi9xdRqa7us/HDGAyQdLGGE5frCBlnwxqVO+ZpceQdTke+bTfmR1IQy9YtvKRdNLt0fXKpy638KnNXGwmuNpIeD7WUGZcBJSavvzLqX1vnLWXGyjfczbv9lOaUtLA8Ib997TTHmx6BYzRa/9FGObGHfJ9Kp/3081/4Cyq06zVl+gQNAAAAAElFTkSuQmCC"
            alt=""
          />
        );
        break;
      case "ko":
        return (
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADdAAAA3QFwU6IHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQVQTFRF////9vb29PT0QUebQkicSE6eTFGgTFKhTlOhU1aiYWFtYWarYmGmYmJvYmesY2NvZWVxZmZyaGh0cHB7cXF9dHR/dnWweXmDfm6nf26ngYGLgoKMhISNhISOhYWOh4eRiIiRkpKak5Obk5Oclpaempqim5ujnJykp6euqKivrK7Qrq60r6+1sbG3srK4ubm+vW+Qvb3Cvr7DwMDFwcHFwsLGw8PHx8fLy8vPy83gzMzPzc3Rzs/i0NHi0tPj12Z+4ODi4eHj4uLj4uLk4+Pl53eH6Ojp61pq7lhn8Fdl8VVk9PT09fX19lJg987Q99DS99LU99TW+bC0/U1Y/Wpy/lZf/0tV8p6CvgAAAAN0Uk5TAFJ4LCUCBgAAARFJREFUOI3VkmlXgkAYhbGptAVTyzbbDNJCmqSy0tyKsoWbC+H8/5/SDHmKIZLPPl/gcB/Oed87oyizQGJuKgkFMfwIPfl7LyyYx7VgXtMqstBVi7r+m+t6cV0SnPxpbpfyl77rDviD7udu95yAQA8yZ1vAyGMcbwhcX2QOaUB4rJTUJwzHzGc8gq2WTFsasmEBz2yCB1iN8JpA88VP3z8Y60f0ABhVkVdT86uvbrSQ6ny+7RDOdrRQJ2RhUeRkafBXuL8E0mTCJh/yThJsU6zZTn7nyRbs5RNpTXqUPedFtVZEnm4DVzdZqSgnb5X9qpuGURc/FMoPhWDV6K5p8mFpG6EhTW36ccdfmH+Jv7SzwBfP2KSoSBn3hQAAAABJRU5ErkJggg=="
            alt=""
          />
        );
        break;
      case "vi":
        return (
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAVZJREFUWIXtlT1Lw1AUhp+YryYYtYKDFEGnIg46u2sVFAqObv4K/4P/wVnRwUFwdbKDDo6OZhBbCra0JK0xSR2UgvSD3qRwQfLCHe45nHMe7jmcC5kySZbyQvFKNkBPJsCMzOL/AED5PbIArC0Da9NIBaClCZ4t2dCDznOQOEeqF3B2LJw9K1UbEgPkNnT0FQ29oGGuJ2/DxHtg7sAmf+L072peRS+oAHy9RUSNqO9rnLdp3frTBQCwt3Msny2iLalD/dFHxPtpA+++M2lKsRb4D13cchW/0h30Vbq8HlaFigsDAIT1GP9pcOr9x4CwHoumSzaEzq4FQOCGBG74YytZSVKJAxirGmZRp33n4x7VcMtVmpceZlHHWBNfK8K/4cKxQ+zFtG68P3Zn30adV2heeCMipwSgmAq9z+Eh43yjJNyCcQVEiycCmLYyAA24lg2RKZNUfQN2v2cybIa9wwAAAABJRU5ErkJggg=="
            alt=""
          />
        );
        break;
      default:
      // code block
    }
  };
  const [scrolling, setScrolling] = useState(false);

  return (
    <Dropdown
      position={dropdownPosition}
      scrolling={scrolling}
      setScrolling={setScrolling}
      target={
        // <Button scale={buttonScale} variant="text" startIcon={<LanguageIcon color={color} width="24px" />}>
        //   {!hideLanguage && <Text color={color}>{currentLang?.toUpperCase()}</Text>}
        // </Button>
        <Box mt="10px" >
          <Button scale={buttonScale} variant="text" onClick={() => {
            setScrolling(false);
          }} startIcon={getIconLang(currentLang)}>
            {!hideLanguage && <Text color={color}>{currentLang?.toUpperCase()}</Text>}
            <Box ml="10px">
              <DropDownIcon width="10" height="8" />
            </Box>
          </Button>
        </Box>
      }
    >
      {langs.map((lang) => (
        <Flex
          alignItems={["left", "left", "left", "left"]}
          justifyContent="left"
          key={lang.locale}
          onClick={() => {
            setLang(lang); setScrolling(true);
          }}
          // Safari fix
          style={{ minHeight: "32px", height: "auto" }}
          padding="10px"
        >
          <a href={`#${lang.code}`}>
            <Flex justifyContent="left" alignItems={["left", "left", "left", "left"]}>
              <Box>{getIconLang(lang.code)}</Box>
              <Box ml="10px" mt="5px">
                {lang.language}
              </Box>
            </Flex>
          </a>
        </Flex>
      ))}
    </Dropdown>
  );
};

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
