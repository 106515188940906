import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32"  fill="url(#paint0_linear_1728_14206)" {...props}>
      <rect y="0.998047" width="32.1142" height="31" rx="15" fill="url(#paint0_linear_1728_14206)" />
      <path
        d="M19.5633 17.435L19.9903 14.7216H17.3596V12.9579C17.3596 12.216 17.7269 11.491 18.9013 11.491H20.1142V9.18035C19.4079 9.06772 18.6942 9.00679 17.9789 8.99805C15.8137 8.99805 14.4001 10.2996 14.4001 12.6527V14.7216H12V17.435H14.4001V23.998H17.3596V17.435H19.5633Z"
        fill="#BDA76B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1728_14206"
          x1="16.0571"
          y1="0.998047"
          x2="16.0571"
          y2="31.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="#373737" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
